import axios from 'axios'
import {
  FETCH_BANK,
  FETCH_BANK_SUCCESS,
  FETCH_BANK_FAILURE,
} from './bankTypes'
import { toast } from 'react-toastify'
import { listBanks } from 'src/services/BankService'

export const fetchBank = () => {
  return (dispatch) => {
    dispatch(fetchBanks())
     listBanks()
      .then((response) => {
        // response.data is the users
        dispatch(fetchBanksSuccess(response.data.banks))
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchBanksFailure(error.response.data))
      })
  }
}

export const fetchBanks = () => {
  return {
    type: FETCH_BANK,
  }
}

export const fetchBanksSuccess = (banks) => {
  return {
    type: FETCH_BANK_SUCCESS,
    payload: banks,
  }
}

export const fetchBanksFailure = (error) => {
  return {
    type: FETCH_BANK_FAILURE,
    payload: error,
  }
}
