import classNames from 'classnames';
import React, { Component } from 'react';
import Select from 'react-select/async';

import { searchFundsControlAccount } from '../services/FundsControlAccountService';

const customControlStyles = base => ({
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc'
});

class FundsControlAccountSelect extends Component {

    constructor(props) {
        super(props)
        this.searchSelectRef = React.createRef();
        this.searchFundsControlAccount = this.searchFundsControlAccount.bind(this);
    }

    componentDidMount() {
        if (this.props.autoFocus) this.searchSelectRef.current.focus();
    }
    searchFundsControlAccount(keyword, callback) {
        searchFundsControlAccount([], keyword)
            .then((response) => {
                let fundsControlAccounts = response.data.funds_control_accounts;
                let options = fundsControlAccounts.map(fundsControlAccount => ({
                    value: fundsControlAccount.id,
                    label: this.props.reportsLabel
                        ? `${fundsControlAccount.name}`
                        : `#${fundsControlAccount.fca_number === null ? ' Not Assigned' : fundsControlAccount.fca_number}\nName: ${fundsControlAccount.name}`,
                    worker_compensation_insurance_expiry_date: fundsControlAccount.worker_compensation_insurance_expiry_date,
                    general_liability_insurance_expiry_date: fundsControlAccount.general_liability_insurance_expiry_date,
                    builder_risk_coverage_expiry_date: fundsControlAccount.builder_risk_coverage_expiry_date,
                    inspection_fee: fundsControlAccount.inspection_fee,
                    count: fundsControlAccount.draw_requests_count
                }));
                callback(options);
            });
    }

    render() {
        let sVal = null;
        if (this.props.value !== undefined) {
            sVal = this.props.value;
        }

        let e = <Select
            components={{
                IndicatorSeparator: () => null
            }}
            ref={this.searchSelectRef}
            onChange={(fundsControlAccount, action) => {
                (this.props.onChange)(fundsControlAccount);
            }}
            isSearchable={true}
            placeholder={this.props.placeholder}
            loadOptions={this.searchFundsControlAccount}
            noOptionsMessage={() => { return "Type to search" }}
            value={sVal}
            isClearable={this.props.isClearable}
            aria-invalid={this.props.invalid ? 'true' : 'false'}
            name={this.props.name}
            className={classNames({
                'is-invalid': this.props.invalid,
                'is-valid': this.props.valid
            })}
            classNames={{
                control: (state) => 'react-select-form-control',
            }}
            classNamePrefix="funds-control-account-select"
            styles={{
                control: customControlStyles,
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            onBlur={() => this.props.onBlur({ target: { name: this.props.name, value: sVal } })}
            isDisabled={this.props.disabled}
        />;

        if (this.props.label) {
            e = <div>
                <label className='form-label'>{this.props.label}</label>
                {e}
            </div>
        }

        return e;
    }
}

FundsControlAccountSelect.defaultProps = {
    autoFocus: false,
    onBlur: () => { }
};

export default FundsControlAccountSelect;


{/*
Usage:
    <FundsControlAccountSelect
        callback={(r) => {
            setFieldValue('fundsControlAccount_id', r.value);
            setFundsControlAccount(r)
        }}
        value={fundsControlAccount}
        invalid={(errors.fundsControlAccount_id && touched.fundsControlAccount_id) || !!status?.apiErrors?.fundsControlAccount_id}
        name="fundsControlAccount_id"
        valid={(!errors.fundsControlAccount_id && touched.fundsControlAccount_id) && !status?.apiErrors?.fundsControlAccount_id}
        onBlur={handleBlur}
    />
*/}
