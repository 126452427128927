import {
  FCA_ERRORS
} from './fcaTypes'

const initialState = {
  loading: false,
  status: '',
  error: '',
  fcaErrors: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FCA_ERRORS:
      return {
        loading: false,
        fcaErrors: action.payload,
        error: '',
      }
    default:
      return state
  }
}

export default reducer
