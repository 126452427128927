import { API_URL } from "./config";

export default function setupAxios(axios) {
    axios.defaults.baseURL = API_URL
    axios.defaults.trailingSlash = false;
    axios.defaults.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
  }
  axios.defaults.withCredentials = true;
  axios.interceptors.request.use(
    config => config,
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    response => {
      return response;
    },
    err => Promise.reject(err)
  );
}