import axios from '../httpClient';

const reportConstructionCost = (fundsControlAccountId, types = []) => axios.get(`/reports/construction-cost?funds_control_account_id=${fundsControlAccountId}&types=${types.join(',')}`);

const reportPortfolioRecap = (dateTo, partyId) => {
    let link = `/reports/portfolio-recap?date_to=${dateTo}`;

    if (partyId) {
        link += `&party_id=${partyId}`;
    }

    return axios.get(link);
}

const reportBankCheck = (type, bankAccountId, dateFrom, dateTo) => axios.get(`/reports/bank-check?type=${type}&bank_account_id=${bankAccountId}&date_from=${dateFrom}&date_to=${dateTo}`);

const reportBankAccount = (bankAccountId, dateFrom, dateTo, type = '') => axios.get(`/reports/bank-account?bank_account_id=${bankAccountId}&date_from=${dateFrom}&date_to=${dateTo}&type=${type}`);

const reportReceipt = (bankAccountId, dateFrom, dateTo) => axios.get(`/reports/receipt?bank_account_id=${bankAccountId}&date_from=${dateFrom}&date_to=${dateTo}`);

const reportInspectionDetail = (fundsControlAccountId) => axios.get(`/reports/inspection?funds_control_account_id=${fundsControlAccountId}`);

const reportFcaTransaction = (fundsControlAccountId) => axios.get(`/reports/fca-transaction?funds_control_account_id=${fundsControlAccountId}`);

const reportFcaBalanceSheet = (fundsControlAccountId) => axios.get(`/reports/fca-balance-sheet?funds_control_account_id=${fundsControlAccountId}`);

export { reportBankAccount, reportBankCheck, reportConstructionCost, reportFcaBalanceSheet, reportFcaTransaction, reportInspectionDetail, reportPortfolioRecap, reportReceipt };
