import { cilFile } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import React from 'react'
import { DownloadTableExcel } from 'react-export-table-to-excel';

function ExcelExport({fileName,tableRef,isDisabled}) {

  return (
    <DownloadTableExcel
      filename={fileName}
      sheet="users"
      currentTableRef={tableRef.current}
  >
    <CButton disabled={isDisabled} style={{background: '#025346',marginLeft: '.5rem'}}>Export XLS <CIcon icon={cilFile} /> </CButton>
  </DownloadTableExcel>
  );
}

export default ExcelExport
