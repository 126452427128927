import { CButton, CButtonGroup, CCard, CCardBody, CContainer, CImage, CTableBody, CTableDataCell, CTableFoot, CTableHead, CTableHeaderCell, CTableRow, CTooltip } from '@coreui/react';
import { useEffect, useRef, useState } from 'react';
import { AppFooter, AppHeader, AppSidebar } from 'src/components';
import { formatCurrency } from 'src/currencyFormatter';
import { reportFcaBalanceSheet } from 'src/services/ReportService';
import FundsControlAccountSelect from 'src/utils/FundsControlAccountSelect';

import { cilFile } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { API_URL } from 'src/config';
import ExcelExport from 'src/utils/ExcelExport';
import moment from 'src/utils/TZMoment';
import axios from '../httpClient';
import refresh from './../assets/images/fca/refresh.png';

const FcaBalanceSheet = ({fca=false,fundsControlAccountId}) => {

  const [fundsControlAccount, setFundsControlAccount] = useState(undefined);
  const [response, setResponse] = useState(undefined);
  const tableRef = useRef(null);

  const loadReport = (fundsControlAccountId) => {
    reportFcaBalanceSheet(fundsControlAccountId).then(response => {
      setResponse(response.data);
    })
  }

  useEffect(() => {
    if(fundsControlAccountId) {
      loadReport(fundsControlAccountId)
      setFundsControlAccount(fundsControlAccountId)
    }
  }, [fundsControlAccountId])


  let sVoucher = 0.00, sDeposit = 0.00, lastBalance = 0.00;

  const formatAddress = (...addressParts) => {
    return addressParts.filter(Boolean).join(', ');
  };

  return (
    <div>
      {!fca && <AppSidebar />}
      <div className={`wrapper d-flex flex-column min-vh-100 ${!fca ? 'bg-light' : ''}`}>
      {!fca && <AppHeader heading="FC Balance Sheet" />}
        <div className="d-flex flex-row align-items-center">
          <CContainer fluid>
            <div className="d-flex justify-content-end">
              <div className='filters'>
                <CButtonGroup className='d-flex align-items-center mb-3'>
                  {!fca &&
                  <>
                  <label className='me-2'>FC: </label>
                  <FundsControlAccountSelect
                    placeholder="Select Fund Control"
                    reportsLabel={true}
                    width="70rem"
                    name="funds_control_account_id"
                    className="select-custom-class"
                    onChange={(fundsControlAccount) => {
                      setFundsControlAccount(fundsControlAccount)
                      loadReport(fundsControlAccount.value);
                    }}
                    value={fundsControlAccount}
                  />
                  </>
                  }
                  <CTooltip content="Requery" placement="top">
                    <CButton className="ms-3" onClick={() => {
                      loadReport(fundsControlAccount?.value || fundsControlAccountId)
                    }}>  <CImage src={refresh} /></CButton>
                  </CTooltip>
                  <ExcelExport fileName="FC Balance Sheet" tableRef={tableRef} isDisabled={response?.rows?.length == 0} />
                  <CButton disabled={response?.rows?.length == 0} style={{ background: '#025346', marginLeft: '.5rem' }} onClick={() => {
                    axios({
                      url: `${API_URL}/reports/fca-balance-sheet?funds_control_account_id=${fundsControlAccount.value || fundsControlAccountId}&output=pdf`,
                      method: 'GET',
                      responseType: 'blob',
                    }).then((response) => {
                      const href = URL.createObjectURL(response.data);
                      const link = document.createElement('a');
                      link.href = href;
                      link.setAttribute('download', 'FCABalanceSheet.pdf');
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                      URL.revokeObjectURL(href);
                    });
                  }}>Export PDF <CIcon icon={cilFile} /> </CButton>
                </CButtonGroup>
              </div>
            </div>

            <CCard className="listing form_modal bordered" ref={tableRef}>
              <CCardBody>
                {response?.rows?.length === 0 && <h6 className='text-center'>No Data</h6>}
                <table className="p-4">
                  <tr>
                    <td style={{ width: "7rem" }}>Order Date:</td>
                    <td style={{ width: "7rem" }}>{response?.funds_control_account.order_date && moment(response?.funds_control_account.order_date).format('m/d/Y')}</td>
                    <td style={{ width: "7rem" }}>Closing Date:</td>
                    <td style={{ width: "7rem" }}>{response?.funds_control_account.closing_date && moment(response?.funds_control_account.closing_date).format('m/d/Y')}</td>
                  </tr>
                  <tr>
                    <td>Location:</td>
                    <td>{response?.funds_control_account.address}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>{response?.funds_control_account.city}, {response?.funds_control_account.state} {response?.funds_control_account.zip_code}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <table className="p-5 mx-auto">
                  <tbody>
                    {response?.funds_control_account.fca_parties.map((fcaParty, index) => {
                      return [
                        <tr key={`{fcaParty.id}-{0}`}>
                          <td className="text-start">{fcaParty.type}</td>
                          <td style={{ paddingLeft: '10px' }}>{fcaParty.party.name}</td>
                          <td className="text-end" style={{ paddingLeft: '5rem' }}>Telephone:</td>
                          <td>{fcaParty.party.telephone_one}</td>
                        </tr>,
                        <tr key={`{fcaParty.id}-{1}`}>
                          <td className="text-end">Address:</td>
                          <td style={{ paddingLeft: '10px' }}>{formatAddress(fcaParty.party.address_one, fcaParty.party.city, fcaParty.party.state)}</td>
                          <td className="text-end">Fax:</td>
                          <td>{fcaParty.party.fax}</td>
                        </tr>,
                        <tr key={`{fcaParty.id}-{2}`}>
                          <td></td>
                          <td style={{ paddingLeft: '10px', maxWidth: '45rem' }}>{formatAddress(fcaParty.party.address_two, fcaParty.party.zip_code)}</td>
                          <td className="text-end">Email:</td>
                          <td>{fcaParty.party.email}</td>
                        </tr>,
                        <tr key={`{fcaParty.id}-{3}`}>
                          <td>&nbsp;</td>
                          <td></td>
                          <td className="text-end"></td>
                          <td></td>
                        </tr>,
                      ]
                    })}
                  </tbody>
                </table>
                <div className='table table-responsive modal-content' style={{ display: response?.rows?.length === 0 && 'none' }}>
                  <table className='table-hover align-middle mb-0 new-table'>
                    <CTableHead className='align-middle'>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Type</CTableHeaderCell>
                        <CTableHeaderCell scope="col">ID</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Party</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Voucher</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Deposit</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Balance</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {response?.rows.map(row => {
                        sVoucher += +row.voucher;
                        sDeposit += +row.deposit;
                        lastBalance = row.balance;

                        return <CTableRow key={row.code}>
                          <CTableHeaderCell scope="col">{row.entry_type}</CTableHeaderCell>
                          <CTableDataCell>{row.id}</CTableDataCell>
                          <CTableDataCell>{row.entry_date ? moment(row.entry_date).format('MM/DD/YYYY') : ''}</CTableDataCell>
                          <CTableDataCell>{row.party_name}</CTableDataCell>
                          <CTableDataCell>{formatCurrency(row.voucher)}</CTableDataCell>
                          <CTableDataCell>{formatCurrency(row.deposit)}</CTableDataCell>
                          <CTableDataCell>{formatCurrency(row.balance)}</CTableDataCell>
                        </CTableRow>
                      })}
                    </CTableBody>
                    <CTableFoot>
                      <CTableRow>
                        <CTableHeaderCell style={{ width: '270px' }}>Total:</CTableHeaderCell>
                        <CTableHeaderCell>{response?.rows.length}</CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                        <CTableHeaderCell>{formatCurrency(sVoucher)}</CTableHeaderCell>
                        <CTableHeaderCell>{formatCurrency(sDeposit)}</CTableHeaderCell>
                        <CTableHeaderCell>{formatCurrency(lastBalance)}</CTableHeaderCell>
                      </CTableRow>
                    </CTableFoot>
                  </table>
                </div>
              </CCardBody>
            </CCard>
          </CContainer>
        </div>
        {!fca && <AppFooter />}
      </div>
    </div>
  )
}

export default FcaBalanceSheet
