import axios from '../httpClient';

const listBanks = () => axios.get('/banks');

const showBank = (id) => axios.get(`/banks/${id}`);
const searchBank = (keyword,signal) => {
    let link = `/banks?search=${keyword}`;

    return axios.get(link,signal);
}

const storeBank = (data) => axios.post('/banks', data);

const updateBank = (id, data) => axios.put(`/banks/${id}`, data);

const deleteBank = (id) => axios.delete(`/banks/${id}`);

export { listBanks, storeBank, updateBank, searchBank, showBank, deleteBank };
