import { getPermissions } from 'src/services/PermissionService';
import {
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
} from './permissionTypes';

// Fetch Permissions Action
export const fetchPermissions = () => {
  return (dispatch) => {
    dispatch(fetchPermissionsRequest());
    getPermissions()
      .then((response) => {
        // response.data contains the permissions
        dispatch(fetchPermissionsSuccess(response.data.permissions));
      })
      .catch((error) => {
        // error.message contains the error message
        dispatch(fetchPermissionsFailure(error.response.data));
      });
  };
};

// Action for fetching permissions request
export const fetchPermissionsRequest = () => {
  return {
    type: FETCH_PERMISSIONS,
  };
};

// Action for successful permissions fetch
export const fetchPermissionsSuccess = (permissions) => {
  return {
    type: FETCH_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
};

// Action for failed permissions fetch
export const fetchPermissionsFailure = (error) => {
  return {
    type: FETCH_PERMISSIONS_FAILURE,
    payload: error,
  };
};
