import { combineReducers } from 'redux'
import bankReducer from './bank/bankReducer'
import sidebar from './sidebar'
import fcaErrors from './fcaErrors/fcaReducer'
import permission from './permissions/permissionReducer'

const rootReducer = combineReducers({
  banks: bankReducer,
  sidebar: sidebar,
  fcaErrors: fcaErrors,
  permission: permission,
})

export default rootReducer
