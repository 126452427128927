import axios from '../httpClient';

const listNotifications = () => axios.get(`notifications`);

const showNotification = (id) => axios.get(`notifications/${id}`);
const searchNotification = (keyword, signal,roleId) => {
  let link = `notifications?search=${keyword}&role_id=${roleId}`;

  return axios.get(link, signal);
}

const updateNotification = (id) => axios.put(`notifications/${id}`,{});

const deleteNotification = (id) => axios.delete(`notifications/${id}`);

export { deleteNotification, listNotifications, searchNotification, showNotification, updateNotification };
