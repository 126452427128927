import React, { Component, Suspense } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-medium-image-zoom/dist/styles.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar/dist/simplebar.min.css';
import FcaBalanceSheet from './layout/FcaBalanceSheetReport';
import './scss/style.scss';
import CallApis from './layout/CallApis';

const loading = (
  <div className="pt-4 mt-4 text-center">
    <h3>It was obnoxious that is why we hid it. Please wait while we load it completely!</h3>
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const ServiceScreen = React.lazy(() => import('./layout/ServiceScreen'))
const BankScreen = React.lazy(() => import('./layout/BankScreen'))
const OfficeScreen = React.lazy(() => import('./layout/OfficeScreen'))
const BankAccountScreen = React.lazy(() => import('./layout/BankAccountScreen'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Profile = React.lazy(() => import('./views/pages/profile/Profile'))
const ViewPublicFormSubmission = React.lazy(() => import('./views/pages/publicFormSubmission/View'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ViewParty = React.lazy(() => import('./views/pages/party/View'))
const ViewUser = React.lazy(() => import('./views/pages/user/View'))
const Document = React.lazy(() => import('./views/pages/document/View'))
const Application = React.lazy(() => import('./views/pages/application'))
const FC = React.lazy(() => import('./views/pages/fca'))
const PCR = React.lazy(() => import('./views/pages/pcr'))
const FcaInspection = React.lazy(() => import('./views/pages/fca-inspection'))
// const AddInspector = React.lazy(() => import('./views/pages/inspector/Add'))
// const ViewInspector = React.lazy(() => import('./views/pages/inspector/View'))
const ViewFundsControl = React.lazy(() => import('./views/pages/fundsControl/View'))
const AddFundsControl = React.lazy(() => import('./views/pages/fundsControl/Add'))
const ViewReceipt = React.lazy(() => import('./views/pages/receipt/View'))
const Role = React.lazy(() => import('./views/pages/role/View'))
const ViewDrawRequest = React.lazy(() => import('./views/pages/drawRequest/View'))
const ViewFcaBudgetTransfer = React.lazy(() => import('./views/pages/fcaBudgetTransfer/View'))
const PrivacyPolicy = React.lazy(() => import('./views/pages/privacy-policy'))
const ViewInspection = React.lazy(() => import('./views/pages/inspection/View'))

const ViewBankCheck = React.lazy(() => import('./views/pages/bankCheck/View'))

const ConstructionCostReport = React.lazy(() => import('./layout/ConstructionCostReport'))
const HardCostBudgetReport = React.lazy(() => import('./layout/HardCostBudgetReport'))
const PortfolioRecapReport = React.lazy(() => import('./layout/PortfolioRecapReport'))
const BankCheckReport = React.lazy(() => import('./layout/BankCheckReport'))
const BankAccountReport = React.lazy(() => import('./layout/BankAccountReport'))
const ReceiptReport = React.lazy(() => import('./layout/ReceiptReport'))
const InspectionDetailReport = React.lazy(() => import('./layout/InspectionDetailReport'))
const FcaTransactionReport = React.lazy(() => import('./layout/FcaTransactionReport'))

const MailScreen = React.lazy(() => import('./views/pages/mail/index'));

class App extends Component {
  render() {
    return <>
      <ToastContainer key="toasts" autoClose={3500} hideProgressBar />
      <CallApis />
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="*" element={<DefaultLayout />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/profile" name="Login Page" element={<Profile />} />
            <Route exact path="/role" name="Login Page" element={<Role />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/service" name="Service Page" element={<ServiceScreen />} />
            <Route exact path="/bank" name="Bank Page" element={<BankScreen />} />
            <Route exact path="/office" name="Office Page" element={<OfficeScreen />} />
            <Route exact path="/party" name="View Party Page" element={<ViewParty />} />
            <Route exact path="/party/:id" name="Party Page" element={<ViewParty />} />
            <Route exact path="/user" name="View User Page" element={<ViewUser />} />
            <Route exact path="/document" name="View Document Page" element={<Document />} />
            <Route exact path="/bank-account" name="Bank Account" element={<BankAccountScreen />} />
            <Route exact path="/fca/inspection" name="fca inspection" element={<FcaInspection />} />
            {/* <Route exact path="/funds-control-account/add" name="Funds Account" element={<AddFundsControl />} /> */}
            {/* <Route exact path="/funds-control-account" name="Funds Account" element={<ViewFundsControl />} /> */}
            <Route exact path="/inspection" name="View Inspection Page" element={<ViewInspection />} />
            <Route exact path="/deposit" name="View receipt Page" element={<ViewReceipt />} />
            <Route exact path="/draw-request" name="View receipt Page" element={<ViewDrawRequest />} />
            <Route exact path="/draw-request/:id" name="View receipt Page" element={<ViewDrawRequest />} />
            <Route exact path="/fca-budget-transfer" name="View fca-budget-transfer Page" element={<ViewFcaBudgetTransfer />} />
            <Route exact path="/check" name="View bank check Page" element={<ViewBankCheck />} />
            <Route exact path="/public-form-submission" name="View public form submission Page" element={<ViewPublicFormSubmission />} />
            <Route exact path="/application" name="Applications Page" element={<Application />} />
            <Route exact path="/funds-control-account" name="FC Page" element={<FC />} />
            <Route exact path="/pcr" name="PCR Page" element={<PCR />} />
            <Route exact path="/privacy-policy" name="Privacy" element={<PrivacyPolicy />} />

            <Route exact path="/report/construction-cost" name="View construction report Page" element={<ConstructionCostReport />} />
            <Route exact path="/report/hard-cost-budget" name="View Hard Cost Budget report Page" element={<HardCostBudgetReport />} />
            <Route exact path="/report/portfolio-recap" name="View portfolio recap Page" element={<PortfolioRecapReport />} />
            <Route exact path="/report/bank-check" name="View bank check Page" element={<BankCheckReport />} />
            <Route exact path="/report/bank-account" name="View bank Account Page" element={<BankAccountReport />} />
            <Route exact path="/report/receipt" name="View receipt Page" element={<ReceiptReport />} />
            <Route exact path="/report/inspection-detail" name="View Inspection Report Page" element={<InspectionDetailReport />} />
            <Route exact path="/report/fca-balance-sheet" name="View FC Balance Sheet Page" element={<FcaBalanceSheet />} />

            <Route exact path="/mail/:partyId" name="View conversation" element={<MailScreen />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  }
}

export default App
