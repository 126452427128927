import {
  FCA_ERRORS,
} from './fcaTypes';

export const setErrorData = (data) => {
  return (dispatch) => {
    dispatch(fetchErrors(data));
  };
};



export const fetchErrors = (errors) => {
  return {
    type: FCA_ERRORS,
    payload: errors,
  }
}

